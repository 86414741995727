import { NavLink } from "@remix-run/react";
import { clsx } from "clsx";
import dedent from "dedent";
import ReactMarkdown, { Components } from "react-markdown";
import remarkGfm from "remark-gfm";

type Props = React.ComponentProps<typeof ReactMarkdown>;

const Markdown: React.FC<Props> = ({
  children,
  components,
  className,
  ...props
}) => {
  const comps: Components = {
    h1: "h2",
    h2: "h3",
    h3: "h4",
    h4: "h5",
    a: ({ node, href, ref, ...props }) =>
      href?.match(/(\w+:)|pdf$/) ? (
        <a
          ref={ref}
          className="underline"
          rel="noreferer noopener"
          target="_blank"
          href={href}
          {...props}
        />
      ) : (
        <NavLink className="underline" to={href || "#"} {...props} />
      ),
  };
  return (
    <ReactMarkdown
      {...props}
      className={clsx(
        className,
        "space-y-4",
        "[&_h2]:font-semibold",
        "[&_h3]:font-semibold",
        "[&_h4]:font-semibold",
        "[&_h5]:font-semibold",
        "[&_ul]:list-inside [&_ul]:list-disc",
        "[&_ol]:list-inside [&_ol]:list-decimal",
        "[&_table]:my-10 [&_table]:leading-relaxed",
        "[&_td]:border [&_td]:p-2",
        "[&_th]:bg-gray-100 [&_th]:p-2",
      )}
      remarkPlugins={[remarkGfm]}
      components={Object.assign(comps, components)}
      children={dedent(children || "")}
    />
  );
};

export default Markdown;
